/*
 * pdp Constants
 * 
 * 
 * 
 * 
 
 * 
 * 
 */


export const NO_COST_EMI_DISCOUNT = 'No Cost EMI Discount';
export const NO_COST_STARTING = 'No Cost EMI starting from'
export const WAVED_OF_BY_GODREJ_INTERIO = '(Waved of by Godrej Interio)';
export const PRODUCT_DETAILS = ' Product Details';
export const PRODUCT_FEATURE = 'Product Feature';
export const PURCHASE_GUIDE = ' Purchase Guide';
export const PRODUCT_ADDED = 'Product added to cart';
export const ADD_TO_CART = 'ADD TO CART';
export const QUANTITY = 'Quantity';
export const NOT_AVAILABLE = 'Quantity is not available';
export const ESTIMATED_DELIVERY = 'Estimated Delivery Charges:';
export const FREE = 'Free';
export const VIEW = 'VIEW';
export const LEARN_MORE = 'LEARN MORE';
export const EMI_EASY = 'STANDARD EMI';
export const NC_EMI_EASY = 'NO COST EMI';
export const EQUAL_MONTHLY_INSTALLMENT = 'Pay for your order in equal monthly easy instalments (EMI), using any one of the payment options in the table below.';
export const CONVERT_THE_PAYMENT = 'Your bank will convert the payment done using credit into EMI in 3-4 working days. Interest on EMI applicable as per your bank/card policies.';
export const NC_EQUAL_MONTHLY_INSTALLMENT = 'Pay for your order in equal monthly easy instalments (EMI), using any one of the payment options in the table below.';
export const NC_CONVERT_THE_PAYMENT = 'Your bank will convert the payment done using credit into EMI in 3-4 working days.';
export const NC_BANK_WILL_CONTINUE = 'The bank will continue to charge interest on No Cost EMI plans as per existing rates. However, the interest to be charged by the bank will be passed on to you as an upfront discount.';
export const NC_GODREJ_DOES_NOT_CHARGE = 'Godrej Interio does not charge any fee for your orders on EMI (including No-cost-EMI). Please check with your bank for any processing fee charges.';
export const DEBIT_CARD_PAY = 'For payments done using credit and debit cards, to make this a No Cost EMI offer, the interest amount will be discounted from the price of your order. Your card will be charged for the item price minus the discounted interest. The total amount you will pay to the bank (excluding GST) will be equal to the price of the item. The bank will charge GST on the interest amount. For payments done using Amazon Pay EMI, the price will not be discounted upfront. Instead, you will not be charged any interest for a No Cost EMI offer.';
export const PLANS = 'PLANS';
export const MONTHS = '(Months)';
export const CHARGED_PROVIDER = '(Charged by provider)';
export const ANNUAL_INTEREST = 'ANNUAL INTEREST';
export const PAYABLE_PROVIDER = '(Payable to provider)';
export const EXP_THIS_PRODUCT = 'Experience this product at your nearest';
export const LOCATE_MORE = 'Locate more';
export const TOTAL_COST = 'TOTAL COST';
export const NOTIFY_ME = 'Notify me at this email address';
export const EMAIL_ADD = 'Email Address';
export const SUBMIT = 'SUBMIT';
export const PRODUCT_ID = 'Product ID:';
export const PRODUCT_FEATURES = 'Product Features';
export const DEL_CHARGES = ' Delivery & Installation';
export const INSTALL_DEL = ' Delivery';
export const VIEW_OFFERS = 'VIEW OFFERS';
export const STARTING_FROM = 'Starting at';
export const EMI = 'EMI';
export const PRODUCTS_YOU_MAY = 'Products You May Like';
export const EMI_STARTING = 'EMI Starting from';
export const ON_THIS_PRODUCT = 'on this product';
export const A_MONTH = '/month';
export const TC_APPLY = 'T&C Apply';
export const SHARE = 'SHARE';
export const WISHLIST = 'WISHLIST';
export const NOT_DELIVER_PRE =
  'We are sorry, we are unable to deliver here. Please try another pincode or ';
export const NOT_DELIVER_POST = 'click here to request a callback.';
export const TAX_DISCLAIMER = 'MRP inclusive of all taxes';
export const PINCODE_NULL = 'Please enter your city pincode to check availability & add the item to your cart';
export const PINCODE_MSG = 'Estimated Delivery Date & Charges';
export const UNAVAILABLE_CURRENTLY = 'The Product you are looking for is currently unavailable.';
export const UNAVAILABLE_EXPLORE_SIMILAR = 'Click below to explore similar exciting products !!';
export const UNAVAILABLE_BROWSER_SIMILAR = 'BROWSE OTHER PRODUCTS';
