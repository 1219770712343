import React from 'react';
import WishListCount from "../Wishlist/wishlist";
import CartCount from '../Cart/cart';
import UserAccInfo from '../UserAccInfo/userAccInfo';
import '../../../public/styles/headerContainer/headerRight.scss';
import { getReleventReduxState, isMobile } from '../../utils/utilityManager';
import connect from 'react-redux/src/connect/connect'
import SearchLogo from '../SVGs/search';
import ImageSearch from '../ImageSearch/index.js';
import apiManager from '../../utils/apiManager';
import { espotAPI } from '../../../public/constants/constants';

class SearchBar extends React.Component {
    state = {
      isLoading: true,
      errors: null,
      showlogin: false,
      isSearchActive: false,
    };

    componentWillReceiveProps(nextProps) {
      this.setState({
        isLoading: false,
      })
    }

    userInfoCallback() {
      this.setState({
        showlogin: true,
      })
    }
	
    onSearchClick() {
      this.setState({
        isSearchActive: true, 
        showlogin: false,
      });
      if (this.props.isSearchActive === false) {
        this.props.onSearchClick(this.props.isSearchActive);
      }
    }

    componentDidMount(){
       document.addEventListener('closePopUpWishlist', event=>{
        this.setState({
          showlogin: false,
        })
     })
      this.onSearchClick()
      this.getEspotData()
    }


    getEspotData(){
      apiManager
      .get(espotAPI + 'GI_NEWUSER_COUPON_POPUP')
      .then(response => {
        this.setState({
          couponData: response.data.data[0],
        }
        );
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
    }
  

    render() {
      return (
        <div className='rightSide'>
          {/* <li className="image-icon icons">
            <SearchLogo isActive={this.props.isSearchActive}  />
            <ImageSearch />
          </li> */}
          <div className='image-search'><ImageSearch /> </div>
          {isMobile() ? <WishListCount/> : <WishListCount userInfoCallbackPro={this.userInfoCallback.bind(this)}/> }
          <CartCount />
          <UserAccInfo couponData = {this.state.couponData} fromWishlistPro={this.state.showlogin} showUserInfo/>
        </div>
      );
    }
}

function mapStateToProps(state) {
  const stateObj = getReleventReduxState(state, 'global');
  const updatedUsername = getReleventReduxState(stateObj, 'userName');

  return {
    reduxUserName: updatedUsername,
  };
}

export default connect(mapStateToProps)(SearchBar);
// export default SearchBar;
