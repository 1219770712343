import React from 'react';
import { Helmet } from "react-helmet";
import apiManager from '../../utils/apiManager';
import { espotAPI } from '../../../public/constants/constants';
import '../../../public/styles/content.scss';
import { imagePrefix } from '../../../public/constants/constants';

class Pixels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pixelData: null,
      isLoading: true,
      error: null,
      url:'',
      urlmaking:'',
      seoEspotNAme:'GI_PDP_SEO_DETAILS',
      productName:'',
      breadcrumbData:'',
      breadcrumbData1:'',
      pdpSEOData:'',
    };
  }

  getSEOData() {
    apiManager
      .get(espotAPI + this.state.seoEspotNAme)
      .then(response => {
        const { data } = response || {};
        this.setState({
          pdpSEOData: data && data.data,
        });
        console.log('PDP SEO data found = '+JSON.stringify(data.data));
      })
      .catch(error => {
        console.log('PDP SEO data not found');
      });
  }

  getEspotData() {
    apiManager
      .get(espotAPI + this.props.espotName)
      .then(response => {
        const { data } = response || {};
        this.setState({
          pixelData: data && data.data.SEOTags,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.productName && this.state.productName=='') {
      this.setState({
        productName: nextProps.productName,
      });
      console.log('productName componentWillReceiveProps = '+nextProps.productName);
    }
    if (nextProps.breadcrumbData && this.state.breadcrumbData=='') {
      this.setState({
        breadcrumbData: nextProps.breadcrumbData,
        breadcrumbData1: nextProps.breadcrumbData && nextProps.breadcrumbData[1] ?nextProps.breadcrumbData[1]:nextProps.breadcrumbData && nextProps.breadcrumbData[0] ?nextProps.breadcrumbData[0]:'' ,
      });
      console.log('breadcrumbData componentWillReceiveProps = '+JSON.stringify(nextProps.breadcrumbData));
      console.log('breadcrumbData1 componentWillReceiveProps = '+JSON.stringify(nextProps.breadcrumbData && nextProps.breadcrumbData[1] ?nextProps.breadcrumbData[1]:nextProps.breadcrumbData && nextProps.breadcrumbData[0] ?nextProps.breadcrumbData[0]:''));
    }
  }

  componentDidMount() {
    // console.log('productName = '+this.props.productName);
    // console.log('breadcrumbData = '+JSON.stringify(this.props.breadcrumbData));
    this.getSEOData();
    if (this.props.espotName) {
      this.getEspotData();
    }
    else {
      this.setState({
        pixelData: {},
        isLoading: false,
      });
    }
  }

  render() {
    const { pixelData } = this.state;
    if (pixelData === null || pixelData === undefined) {
      return null;
    }
    this.state.url = window.location.href;
    this.state.urlmaking = this.state.url.split('?')[0];
    const fullImagePath = `${imagePrefix}${this.props.image}`;
    const keywords = Array.isArray(pixelData.keywords) && pixelData.keywords && pixelData.keywords.length !== 0 ? pixelData.keywords : this.props.keywords;
    return (
      <Helmet>
        {!this.props.isPdp && <title>{pixelData.title ? pixelData.title : this.props.title}</title>}
        {!this.props.isPdp && <meta name="description" content={pixelData.description ? pixelData.description : this.props.description} />}
        {!this.props.isPdp && <h1 className='heading-desc'>{pixelData.metaDescription}</h1>}
        {this.props.isPdp && this.props.isPdp == true && this.state.pdpSEOData && this.state.pdpSEOData.titleString1 &&
          this.state.pdpSEOData.titleString2 && this.state.productName && this.state.productName != '' &&
          <title>{this.state.pdpSEOData.titleString1 + this.state.productName + this.state.pdpSEOData.titleString2}</title>
        }
        {this.props.isPdp && this.props.isPdp == true && this.state.pdpSEOData && this.state.pdpSEOData.descriptionString1 && this.state.pdpSEOData.descriptionString2 &&
          this.state.pdpSEOData.descriptionString3 && this.state.pdpSEOData.descriptionString4 && this.state.productName && this.state.productName != '' &&
          <meta name="description"
            content={this.state.pdpSEOData.descriptionString1 + this.state.productName +
              (this.state.breadcrumbData1 && this.state.breadcrumbData1[1] && this.state.breadcrumbData1[1].label ? this.state.pdpSEOData.descriptionString2 : this.state.pdpSEOData.descriptionString3) +
              (this.state.breadcrumbData1 && this.state.breadcrumbData1[1] && this.state.breadcrumbData1[1].label && this.state.breadcrumbData1[1].label) +
              this.state.pdpSEOData.descriptionString4} />
        }
        {this.props.isPdp && this.props.isPdp == true && this.state.productName && this.state.productName != '' &&
          <h1 className='heading-desc'>{this.state.productName}</h1>}
        
        {!!keywords && (<meta
          name="keywords" content={
            keywords.map((keywordsData) => (
              keywordsData
            ))
          }></meta>)}
        <meta httpEquiv="X-UA-Compatible" content="IE=EmulateIE7"/>
        <link rel="canonical" href={this.state.urlmaking} />
        <meta name="twitter:card" content={pixelData.twitter_card ? pixelData.twitter_card : "summary_large_image"} />
        <meta name="twitter:site" content={pixelData.twitter_site ? pixelData.twitter_site : "@godrejinterio"} />
        <meta name="twitter:title" content={pixelData.title ? pixelData.title : this.props.title} />
        <meta name="twitter:description" content={pixelData.description ? pixelData.description : this.props.description} />
        <meta name="twitter:image" content={pixelData.twitter_image ? pixelData.twitter_image : fullImagePath} />
        <meta name="twitter:image:alt" content={pixelData.twitter_image_alt ? pixelData.twitter_image_alt : this.props.alt} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pixelData.title ? pixelData.title : this.props.title} />
        <meta property="og:description" content={pixelData.description ? pixelData.description : this.props.description} />
        <meta property="og:image" content={pixelData.og_image ? pixelData.og_image : fullImagePath} />
      </Helmet>
    );
  }
}

export default Pixels;
