/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, {Suspense, lazy} from 'react';
import  Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route'
import { LastLocationProvider } from 'react-router-last-location';
import createBrowserHistory from 'history/createBrowserHistory';
import apiManager from '../../utils/apiManager';
import { registerGuestUser } from '../../utils/initialManager';
import { getCookie, isMobile, isTab } from '../../utils/utilityManager';
import LoadingIndicator from '../../utils/loadingIndicator';
// import HIOrphanPage from "../../components/StaticPages/HIOrphanPage";
import * as  firebase from 'firebase/app';
import { initializeApp, getApp, getApps } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";
import './firebase-messaging-sw'
const HIOrphanPage = lazy(()=>import("../../components/StaticPages/HIOrphanPage"))
const MattressFeedback = lazy(()=>import('../MattressFeedback'))
import { triggerNotificationPermission } from '../../utils/gtm';
import {
  accessTokenCookie,
  getTheAccessToken,
  newsletterStatusAPI,
  ipDataApi,
} from '../../../public/constants/constants';

import '../../../public/firebase-messaging-sw'


import appCookie from '../../utils/cookie';
import {Helmet} from "react-helmet";

// import HomePageContainer from '../HomePageContainer/index';
import HomePageContainer from '../HomePageContainer/homepage';
import HeaderContainer from '../HeaderContainer/index';
// import ClpContainer from '../ClpContainer/index';
const ClpContainer = lazy(()=>import('../ClpContainer/index'));
import PlpContainer from '../PlpContainer/index';
// import PdpContainer from '../PdpContainer/pdp';
const PdpContainer = lazy(()=>import('../PdpContainer/pdp'));
import FooterContainer from '../FooterContainer/footer';
import RegisterNow from '../../components/RegisterComponent/registerModalData';
import ForgotpassContainer from '../ForgotPasswordContainer/forgotpassword';
// import NewsletterModel from '../../components/NewsletterModel/newsletterModel';
import CompareContainer from '../comparePageContainer/index';
import CheckoutContainer from '../checkoutContainer/index';
import '../../../public/styles/app.scss';
import MyWishlist from '../../components/MyWishlist/myWishlist';
const AboutUsContainer = lazy(()=>import('../aboutUsContainer/index'))
// import AboutUsContainer from '../aboutUsContainer/index';
import Inspiration from '../InspirationCont/index';
// import Kitchens from '../KitchensContainer';
const Kitchens = lazy(()=>import('../KitchensContainer'))
// import SteelChefKitchen from '../KitchensContainer/kitchen2';
const SteelChefKitchen = lazy(()=>import('../KitchensContainer/kitchen2'))
// import WillowKitchen from '../KitchensContainer/willowKitchen';
const WillowKitchen = lazy(()=>import('../KitchensContainer/willowKitchen'))
import OfferScroll from "../../components/TopFurniturecategories/OfferScroll";
import InspirationDetails from '../InspirationDetailsContainer/index';
import WardrobesContainer from '../wardrobesContainer';
import ModularWardrobeDetails from '../wardrobesContainer/details';
import privacyPolicy from '../privacyPolicy/index';
import HelpSupport from '../serviceSupportContainer/index';
import TermsConditions from '../termsAndConditions/index';
import CookiePolicy from '../CookiePolicy/index';
const ProductFeedback = lazy(()=>import('../productFeedback/index'))
import MyAccount from '../MyAccountContainer/index';
const ServicePageNew = lazy(()=>import('../../containers/servicePageContainer/index'))
// import GuestTrackOrder from '../../components/MyAccountComponents/GuestTrackOrder/guestTrackOrder';
const GuestTrackOrder = lazy(()=>import('../../components/MyAccountComponents/GuestTrackOrder/guestTrackOrder'))
import OrderConformtion from '../orderConfirmation/index';
import CartDetail from '../CartContainer/cartContainer';
// import StoreLocator from '../../components/StoreLocator/storeLocator';
const StoreLocator = lazy(()=>import('../../components/StoreLocator/storeLocator'))
import Directions from '../../components/StoreLocator/index';
import LightHeader from '../../components/HeaderComponent/headerL1/lightHeader';
// const Invoice = lazy(()=>import('../../components/MyAccountComponents/MyOrder/invoice'))
const FurnitureSets =  lazy(()=>import('../FurnitureSets'))
// import Invoice from '../../components/MyAccountComponents/MyOrder/invoice';
import paymentWait from '../../components/checkout/paymentWait';
import NotFound from '../HomePageContainer/notfound';
import InternetError from '../HomePageContainer/InternetError';
import Maintenance from '../HomePageContainer/Maintenance';
import Shipping from '../shippingContainer/index';
import CookiePopup from '../../components/GlobalComponents/cookiepolicywidget';
import ReactSnackBar from "../HomePageContainer/snackbar/index";
import MWebLogo from '../../components/SVGs/mWebLogo';
import Covid19Awareness from '../../components/Covid19Awareness';
import ServiceRequestFormGuest from '../../components/ServiceRequestFormGuest/index';
import CustomerLeads from '../../components/CustomerLeads';
import OrganizationSchema from '../../components/GSchemas/organization';
import StreetViewComponent from '../../components/StreetView';
import ThankYou from '../../components/ThankYou/index';
import thank from '../../components/ThankYou/thank';
import ImageSearchListing from '../../components/ImageSearch/listing';
// import HomeInteriors from '../../components/StaticPages/HomeInteriors';
const HomeInteriors = lazy(()=>import('../../components/StaticPages/HomeInteriors'))
// import KitchenRCB from '../../components/KitchensComp/KitchenRCB';
const KitchenRCB = lazy(()=>import('../../components/KitchensComp/KitchenRCB'))
import FurnitureRCB from '../../components/KitchensComp/FurnitureRCB';

import GodrejAVSolution from '../../components/StaticPages/GodrejAVSolutions';
import FranchiseEnquiry from '../../components/StaticPages/FranchiseEnquiry';
import RapidRooms from '../../components/StaticPages/RapidRooms';

const BlogContainer = lazy(()=>import('../BlogContainer/index'))
// import BlogCategoryContainer from "../BlogContainer/categoryContainer";
const BlogCategoryContainer = lazy(()=>import('../BlogContainer/categoryContainer'))
// import BlogDetailContainer from '../BlogContainer/blogDetailContainer';
const BlogDetailContainer = lazy(()=>import('../BlogContainer/blogDetailContainer'))
const BlogTagContainer = lazy(()=>import('../BlogContainer/tagContainer'))
// import BlogTagContainer from '../BlogContainer/tagContainer';
const CollectionSet = lazy(() =>import('../../components/WeddingCollections/CollectionSet'));
const WeddingCollections = lazy(() =>import('../WeddingCollections'));
import Sitemap from '../../components/StaticPages/Sitemap';
const userAgent = window.navigator.userAgent.toLowerCase();
const isIPad = /ipad/.test(userAgent);
// import  {createBrowserHistory} from 'history';
// export const history =createBrowserHistory();

// history.listen(({pathname}) => {
//   shouldScrollLogin && window.scrollTo(0,0)
// });
import kalista from '../../components/ProductConfigurator/kalista';
import highrolla from '../../components/ProductConfigurator/highrolla';
import broadway from '../../components/ProductConfigurator/broadway';
import kreationX3 from '../../components/ProductConfigurator/kreationX3';
import { espotAPI } from '../../../public/constants/constants';

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 760,
      accessToken: '',
      showNewsLetter: false,
      prevPath: '',
      currPath: '',
      loading: true,
      internetErrorMessage: "",
      showSnackBar: false,
      nitrogenScript:'',
    };
    this.resize = this.resize.bind(this);
    this.guestLoginCallback = this.guestLoginCallback.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.newsletterPopupHandling = this.newsletterPopupHandling.bind(this);
  }

  getEspotData() {
    apiManager
      .get(espotAPI + 'GI_Nitrogen_Script')
      .then(response => {
        const {data} = response || {};
        // console.log(`nitrogenScript = ${JSON.stringify(data.data.nitrogenScript)}`);

        this.setState({
          nitrogenScript: data && data.data.nitrogenScript,
        });
      })
      .catch(error => {
        console.log("no data.");
      });
  }

    clearAllCookies(){
    const cookies = document.cookie.split(";");
    // console.log('all document cookies = '+JSON.stringify(cookies));
    const date = new Date();
    date.setTime(date.getTime() + '24 * 60 * 60 * 1000');
    const expires = `; expires=${date.toGMTString()}`;
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      name=name.trim();
      const value = eqPos > -1 ? cookie.substr(eqPos+1) : cookie;
      // console.log('all document cookie name  = '+JSON.stringify(name));
      // console.log('all document cookie value  = '+JSON.stringify(value));
      // document.cookie = `${name}=;${expires}; path=/;`;
      // document.cookie = `${name}=; expires=Thu, 11 Jun 2024 00:00:00 GMT; path=/;`;
      // appCookie.delete(name,0,'path=/');
      if(name=='recentProduct'){
        appCookie.delete(name,0,'path=/');
      }
      if(name=='accessToken'|| name=='userID'){
        appCookie.set(name,value,6 * 60 * 60 * 1000);
      }
      // appCookie.set(name,value,2*24 * 60 * 60 * 1000);
    }
  }


  componentDidMount() {
    this.clearAllCookies();
    this.getEspotData();
    if (window.location.pathname === '/internet-error') {
      history.goBack();
    }
  
   
   if (window.location.pathname === '/') {
    if(!isMobile()){
      setTimeout(()=>{
        let registerPopupEVent = new Event("registerPopupEVent",{ bubbles: true,});
        registerPopupEVent.simulated = true;
        document.dispatchEvent(registerPopupEVent);
      }, 6000)
    }else{
      setTimeout(()=>{
        let registerPopupEVent = new Event("registerPopupEVentMobile",{ bubbles: true,});
        registerPopupEVent.simulated = true;
        document.dispatchEvent(registerPopupEVent);
      }, 6000)
    }
  } 

    window.addEventListener('resize', this.resize);
    window.addEventListener('load', this.handleLoad);
    window.addEventListener('online', this.onOnline.bind(this));
    window.addEventListener('offline', this.onOffline.bind(this));
    this.initialLoginHandling();
    // this.newsletterPopupHandling();
    this.cookiePolicyPopup();
    this.resize();
    this.getCurrentLocation();
    this.getIPData();
    this.setupFirebaseEndpoint();
 

}



setupFirebaseEndpoint =async()=>{
  let var_permission = null
    const firebaseConfig = {
        apiKey: "AIzaSyB0aHNgnXqMzT_zSIL_77gih2trQRcZMOo",
        authDomain: "godrejinterio-prod.firebaseapp.com",
        projectId: "godrejinterio-prod",
        storageBucket: "godrejinterio-prod.appspot.com",
        messagingSenderId: "574172475594",
        appId: "1:574172475594:web:2a4a19e23eedfa53e9c433",
        measurementId: "G-K3JQBK7WYP"
    };
    
    const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
    let reg

    if ('serviceWorker' in navigator) {
    await  navigator.serviceWorker.register('/sw.js', {
          scope: '/firebase-cloud-messaging-push-scope'})
        .then(async function(registration) {
      // Registration was successful
      console.log('ServiceWorker registration successful with scope');
      reg = registration
      await Notification.requestPermission()
      .then(async(permission) => {
        console.log('permission')
        triggerNotificationPermission('Trigger')
        var_permission = permission
      })
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    })

    // check if SW is active, then run the code
    navigator.serviceWorker.ready.then(async(registration) => {
      // console.log(`A service worker is active: ${registration.active}`);
      console.log('activate SW')
      const messaging = getMessaging(); 
        if (var_permission === 'granted') {
        triggerNotificationPermission('true')
        console.log('permission granted')
        const token = await getToken(messaging, { vapidKey: 'BEVg8T9_stqnNl-oJ4eelRe-Uq7AqYWelE10IIibXH5CAhnq7dpVH7yNaMFakF0CUnBT0MlPkxz-M3GWAHT54HQ',serviceWorkerRegistration : registration })
        console.log(token);
        }
          else{
          triggerNotificationPermission('false')
        }
      // At this point, you can call methods that require an active
    });
    }
  }





  onOnline() {

    this.setState({
      internetErrorMessage: "Internet connected",
      showSnackBar: true,
    });


    setTimeout(() => {
      this.setState({
        internetErrorMessage: "",
        showSnackBar: false,
      });
    }, 3000);

  }

  onOffline() {

    this.setState({
      internetErrorMessage: "Internet disconnected",
      showSnackBar: true,
    });

    setTimeout(() => {
      this.setState({
        internetErrorMessage: "",
        showSnackBar: false,
      });
    }, 3000);

  }

  componentWillUnmount() {
    window.removeEventListener('online', this.onOnline.bind(this), false);
    window.removeEventListener('offline', this.onOffline.bind(this), false);
  }

  componentWillMount() {
    if (isMobile() || isTab() || isIPad) {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
    }
  }


    setupRedirects(ogPath, newPath){
    if(window.location.pathname==ogPath){
      window.location.pathname=newPath;
    }
  }



  componentWillUpdate() {
    const header = document.getElementById("header");
    const pathurl = window.location.href;
    if (header) {
      header.classList.remove("sticky");
    }


        this.setupRedirects('/furniture-2-doors', '/furniture-2-door-wardrobes')
    this.setupRedirects('/furniture-3-doors', '/furniture-3-door-wardrobes')
    this.setupRedirects('/furniture-dining-sets-eight-seater', '/furniture-8-seater-dining-sets')
    this.setupRedirects('/furniture-dining-sets-four-seater', '/furniture-4-seater-dining-sets')
    this.setupRedirects('/furniture-dining-sets-six-seater', '/furniture-6-seater-dining-sets')
    this.setupRedirects('/furniture-dining-tables-eight-seater', '/furniture-8-seater-dining-tables')
    this.setupRedirects('/furniture-dining-tables-four-seater', '/furniture-4-seater-dining-tables')
    this.setupRedirects('/furniture-dining-tables-six-seater', '/furniture-6-seater-dining-tables')
    this.setupRedirects('/furniture-dressers', '/furniture-dressing-tables')
    this.setupRedirects('/furniture-sofas-l-shaped-sofas', '/furniture-sectionals-loungers')
    this.setupRedirects('/furniture-sofas-recliners', '/furniture-recliners')
    this.setupRedirects('/furniture-sofas-sofa-cum-beds', '/furniture-sofa-cum-beds')
    this.setupRedirects('/furniture-sofas-sofa-sets', '/furniture-sofa-sets')
    this.setupRedirects('/furniture-buffet-tables-and-sideboards', '/furniture-tables')
    this.setupRedirects('/furniture-soft-furnishing', '/furniture-soft-furnishings')
    this.setupRedirects('/furniture-study-tables', '/furniture-workdesks-and-study-tables')

    

    
    if(window.location.pathname=='/furniture-online-vistara-king-size-bed-box-storage-cola-rain/56101515SD00506'){
      window.location.pathname='/furniture-online-vurv-king-size-bed-box-storage-cola-rain/56101515SD00506';
    }
    if(window.location.pathname=='/furniture-online-vistara-queen-size-bed-box-storage-cola-rain/56101515SD00507'){
      window.location.pathname='/furniture-online-vurv-queen-size-bed-box-storage-cola-rain/56101515SD00507';
    }
    if(window.location.pathname=='/furniture-online-vistara-wooden-wardrobe-3-door-cola-rain/56101509SD00448'){
      window.location.pathname='/furniture-online-vurv-wooden-wardrobe-3-door-cola-rain/56101509SD00448';
    }

    if(window.location.pathname=='/online-furniture-kitchen'){

      window.location.pathname='/kitchens';

    }

    if(window.location.pathname=='/online-furniture-chef-kitchen'){

      window.location.pathname='/kitchens-steel-chef';

    }

    if(window.location.pathname=='/online-furniture-willow-kitchen'){

      window.location.pathname='/kitchens-willow-wok';

    }

    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.substr(1));
      if (element) {
        element.scrollIntoView();
      }
      else {
        $('html, body').animate({ scrollTop: 0 }, 'smooth');
      }
    }
    else if ((pathurl.includes("sort") || pathurl.includes("filter")) && !(isMobile() || isTab())) {
      $('html, body').stop().animate();
    }
    else if ((pathurl.includes("sort") || pathurl.includes("filter")) && !(isMobile() || isTab())) {
      $('html, body').animate({ scrollTop: 0 }, 'smooth');
    }
    else if ((pathurl.includes("furniture-online")) && !(isMobile() || isTab())) {
      window.scrollTo(0, 0);
    }
    else if (!isMobile() && !isTab() && !isIPad) {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
    }
  }


  initialLoginHandling() {
    const token = getCookie(accessTokenCookie);
    if (token != '') {
      this.setState({ accessToken: token });
    } else {
      /* Check if User is logged-in or Guest */
      // if (isLoggedIn) {
      // } else {
      registerGuestUser(this.guestLoginCallback);
      // }
    }
  }

  newsletterPopupHandling() {
    console.log(`LETTER STAT:${this.state.showNewsLetter}`);
    // if(this.state.showNewsLetter===false){
    this.setState({ showNewsLetter: true });
    // }
    // this.setState({ showNewsLetter: true });
    // if (
    //   getCookie(newsletterTokenCookie) &&
    //   getCookie(newsletterTokenCookie) != null
    // ) {
    //   this.setState({ showNewsLetter: false });
    // } else {
    // this.setState({ showNewsLetter: true });
    // Hit api if NewsletterCookie is null/Empty
    // If yes -> Don't show the Popup
    // If No -> Show the Pop UP
    this.getNewsletterSubscriptionStatus();
    // this.setState({ showNewsLetter: true });
    // }
  }

  cookiePolicyPopup() {
    if (appCookie.get('isCookiePolicy') !== 'false') {
      appCookie.set('isCookiePolicy', true, 365 * 24 * 60 * 60 * 1000);
    }
  }

  getNewsletterSubscriptionStatus() {
    apiManager
      .get(newsletterStatusAPI)
      .then(response => {
        if (!response.data.data.alreadySubscribed) {
          this.setState({ showNewsLetter: true });
        }
      })
      .catch(error => { });
  }

  guestLoginCallback(token) {
    if (token != '') {
      getTheAccessToken(token);
      this.setState({ accessToken: token });
      this.getNewsletterSubscriptionStatus();
    } else {
    }
  }

  handleLoad() {
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.substr(1));
      if (element)
        element.scrollIntoView();
    }
    // window.checkHaptik()
  }

  resize() {
    this.setState({ isMobile: window.innerWidth <= 760 });
  }

  // IP Data Call.
  getIPData() {

    if (appCookie.get('pincode') === null || appCookie.get('pincode') === '') {
      appCookie.set('pincode', '400001', 365 * 24 * 60 * 60 * 1000);
      navigator.geolocation.getCurrentPosition(
        () => {
          appCookie.set('pincode', '400001', 365 * 24 * 60 * 60 * 1000);
        }
      );

      navigator.geolocation.watchPosition((position) => {
        const request = new XMLHttpRequest();
        request.open('GET', ipDataApi);
        request.setRequestHeader('Accept', 'application/json');
        request.send();
        request.onreadystatechange =
          function () {
            if (this.status == 200) {
              const ipData = JSON.parse(this.responseText);
              if (ipData && ipData.postal) {
                const ipDataPostCode = ipData.postal;
                appCookie.set('pincode', ipDataPostCode, 365 * 24 * 60 * 60 * 1000);
              }
              else {
                appCookie.set('pincode', '400001', 365 * 24 * 60 * 60 * 1000);
              }
            }
            else {
              appCookie.set('pincode', '400001', 365 * 24 * 60 * 60 * 1000);
  						//   Geocode.setApiKey(mapKey);
						  //   Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
              //   response => {
              // 	  const address = response.results[0].formatted_address;
              // 	  const data = address.replace(', India', '');
              // 	  const postalCode = data.substr(data.length -6);
              // 	  if (validatePindcode(postalCode) === true && appCookie.get('pincodeUpdated') !== 'true') {
              // 		appCookie.set('pincode', postalCode, 365 * 24 * 60 * 60 * 1000);
              // 		this.setState({
              // 		  loading: false
              // 		})
              //     }
              //     else{
              //       appCookie.set('pincode', '400001', 365 * 24 * 60 * 60 * 1000);
              //     }          
              // 	},
              // 	error => {
              // 		appCookie.set('pincode', '400001', 365 * 24 * 60 * 60 * 1000);
              // 	}
              // )
            }
          };
      },
      (error) => {
        if (error.code == error.PERMISSION_DENIED)
          appCookie.set('pincode', '400001', 365 * 24 * 60 * 60 * 1000);
      });
    }
  }

  getCurrentLocation() {
    // if (appCookie.get('pincode') === null || appCookie.get('pincode') === '') 
    // {
    //    appCookie.set('pincode', '400001', 365 * 24 * 60 * 60 * 1000);
    // }
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(showPosition.bind(this));
    // }
    // function showPosition(position) { }
  }

  checkSearchInput() {
    if (window.location.pathname !== '/search' && document.getElementById("searchInput")) {
      document.getElementById("searchInput").value = '';
      const crossbtn = document.getElementById('clearField');
      if (crossbtn) {
        crossbtn.style.display = 'none';
      }
    }
  }

  mountPageHeader = () => {
    if (window.location.pathname.includes('/check/payment/')) {
      return '';
    }
    if (
      window.location.pathname === '/cart' ||
      window.location.pathname === '/checkout'
    ) {
      return <LightHeader />;
    }
    /* if (window.location.pathname === '/request-callback') {
      return <HeaderType2 />;
    } */
    if (
      window.location.pathname === "/modular-kitchen-enquiries" ||
      window.location.pathname === "/modular-kitchen-enquiries-north" ||
      window.location.pathname === "/modular-kitchen-enquiries-south" ||
      window.location.pathname === "/modular-kitchen-enquiries-east" ||
      window.location.pathname === "/modular-kitchen-enquiries-west"
    ) {
      // return <KitchenRCBHeader />;
      return '';
    }
    if (
      window.location.pathname === "/home-furniture-enquiries" ||
      window.location.pathname === "/home-furniture-enquiries-north" ||
      window.location.pathname === "/home-furniture-enquiries-northeast" ||
      window.location.pathname === "/home-furniture-enquiries-northwest" ||
      window.location.pathname === "/home-furniture-enquiries-southatk" ||
      window.location.pathname === "/home-furniture-enquiries-southtk" ||
      window.location.pathname === "/home-furniture-enquiries-east" ||
      window.location.pathname === "/home-furniture-enquiries-west" ||
      window.location.pathname === "/home-furniture-enquiries-central" ||
      window.location.pathname === '/warrantyfeedback' ||
      window.location.pathname === '/feedback'
    )  {
      // return <FurnitureRCBHeader />;
      return '';
    }
    if (window.location.pathname === '/av-solutions') {
      return '';
    }
    if (window.location.pathname === "/home-interiors-enquiries") {
      return "";
    }
    if (window.location.pathname === '/franchise-enquiry') {
      return '';
    }
     if (window.location.pathname === '/sitemap') {
      return '';
    }
    if (window.location.pathname === '/rapid-rooms') {
      return '';
    }
    if (window.location.pathname === '/kalista') {
    return '';
    }
    if (window.location.pathname === '/highrolla') {
        return '';
    }  
    if (window.location.pathname === '/broadway') {
      return '';
    }
    if (window.location.pathname === '/kreationX3') {
        return '';
    }  
    return <><OfferScroll /><HeaderContainer /></> ;
  };

mountPageFooter = () => {
  if (
    window.location.pathname === "/modular-kitchen-enquiries" ||
        
      window.location.pathname === "/modular-kitchen-enquiries-north" ||
      window.location.pathname === "/modular-kitchen-enquiries-south" ||
      window.location.pathname === "/modular-kitchen-enquiries-east" ||
      window.location.pathname === "/modular-kitchen-enquiries-west"
  ) {
    return '';
  }
  if (
    window.location.pathname === "/home-furniture-enquiries" ||
    
      window.location.pathname === "/home-furniture-enquiries-north" ||
      window.location.pathname === "/home-furniture-enquiries-northeast" ||
      window.location.pathname === "/home-furniture-enquiries-northwest" ||
      window.location.pathname === "/home-furniture-enquiries-southatk" ||
      window.location.pathname === "/home-furniture-enquiries-southtk" ||
      window.location.pathname === "/home-furniture-enquiries-east" ||
      window.location.pathname === "/home-furniture-enquiries-west" ||
      window.location.pathname === "/home-furniture-enquiries-central" ||
      window.location.pathname === "/thank-you-homeinteriors" ||
      window.location.pathname === '/warrantyfeedback' ||
      window.location.pathname === '/feedback'
  )  {
    return '';
  }
  if (
		  window.location.pathname === "/thank-you-north" ||
		  window.location.pathname === "/thank-you-northwest" ||
		  window.location.pathname === "/thank-you-northeast" ||
		  window.location.pathname === "/thank-you-southatk" ||
		  window.location.pathname === "/thank-you-southtk" ||
		  window.location.pathname === "/thank-you-east" ||
		  window.location.pathname === "/thank-you-west" ||
		  window.location.pathname === "/thank-you-central" ||
		  window.location.pathname === "/thank-you-south" ||
		  window.location.pathname === "/thank-you-northm" ||
		  window.location.pathname === "/thank-you-westm" ||
		  window.location.pathname === "/thank-you-eastm" ||
		  window.location.pathname === "/thank-you-furniture" ||
		  window.location.pathname === "/thank-you-modular" 
  ) {
    return '';
  }
  if (window.location.pathname === '/av-solutions') {
    return '';
  }
  if (window.location.pathname === '/franchise-enquiry') {
    return '';
  }
  if (window.location.pathname === '/rapid-rooms') {
    return '';
  }
  if (window.location.pathname === '/kalista') {
    return '';
  }
  if (window.location.pathname === '/highrolla') {
      return '';
  }  
  if (window.location.pathname === '/broadway') {
    return '';
  }
  if (window.location.pathname === '/kreationX3') {
      return '';
  }  
  return <FooterContainer />;
};

  renderAllPagesCommonSnippets = () => (
    <>
      <OrganizationSchema />
    </>
  );

  render() {
    const currentRoute = window.location.href.toString();
    if (currentRoute.includes("imagestore")) {
      this.redirectToB2B();
      return <></>;
    }

    if (this.state.accessToken === '') {
      return <LoadingIndicator />;
    }
    this.checkSearchInput();

    // let newsletterItem;
    // if (this.state.showNewsLetter) {
    //   newsletterItem = <NewsletterModel />;
    // } else {
    //   newsletterItem = null;
    // }

    const { isMobile } = this.state;
    return (
      <>
      <Helmet>
      <script async defer src={this.state.nitrogenScript}></script>
      </Helmet>
      <div>
        
        {appCookie.get('isCookiePolicy') === 'true' ? <CookiePopup /> : null}
        {/* {newsletterItem} */}
        {this.mountPageHeader()}
	  <div id="mainContainer">
          {this.renderAllPagesCommonSnippets()}
          {/* <NewsletterModel/> */}
          <LastLocationProvider>
          <Suspense fallback={<div>LOADING</div>} >
            <Switch>
              <Route exact path="/" component={HomePageContainer} />
              <Route path="/kalista" component={kalista} />
              <Route path="/highrolla" component={highrolla} />
              <Route path="/broadway" component={broadway} />
              <Route path="/kreationX3" component={kreationX3} />
              <Route path="/thank-you" component={ThankYou} />
              <Route path="/thank-you-homeinteriors" component={ThankYou} />
              <Route
                path="/home-interiors-enquiries"
                component={HIOrphanPage}
              />
		   <Route path="/thank-you-furniture" component={thank} />
              <Route path="/thank-you-north" component={thank} />
              <Route path="/thank-you-northwest" component={thank} />
              <Route path="/thank-you-northeast" component={thank} />
              <Route path="/thank-you-southatk" component={thank} />
              <Route path="/thank-you-southtk" component={thank} />
              <Route path="/thank-you-east" component={thank} />
              <Route path="/thank-you-west" component={thank} />
              <Route path="/thank-you-central" component={thank} />
              <Route path="/thank-you-modular" component={thank} />
              <Route path="/thank-you-southm" component={thank} />
              <Route path="/thank-you-northm" component={thank} />
              <Route path="/thank-you-westm" component={thank} />
              <Route path="/thank-you-eastm" component={thank} />
		  <Route
                path="/modular-kitchen-enquiries"
                component={KitchenRCB}
              />
              <Route
                path="/modular-kitchen-enquiries-north"   component={KitchenRCB}
              />
              <Route path="/modular-kitchen-enquiries" component={KitchenRCB} />
              
              <Route
                path="/modular-kitchen-enquiries-south"
                component={KitchenRCB}
              />
              <Route
                path="/modular-kitchen-enquiries-east"
                component={KitchenRCB}
              />
              <Route
                path="/modular-kitchen-enquiries-west"
                component={KitchenRCB}
              />
              <Route
                path="/home-furniture-enquiries"
                component={FurnitureRCB}
              />
              <Route
                path="/home-furniture-enquiries-north"
                component={FurnitureRCB}
              />
              <Route
                path="/home-furniture-enquiries-northwest"
                component={FurnitureRCB}
              />
              <Route
                path="/home-furniture-enquiries-northeast"
                component={FurnitureRCB}
              />

                 <Route path='/warrantyfeedback' component={MattressFeedback} /> 

              <Route
                path="/home-furniture-enquiries-southtk"
                component={FurnitureRCB}
              />
               <Route
                path="/furniture-sets"
                component={FurnitureSets}
              />
              <Route
                path="/furniture-wedding-collections"
                component={WeddingCollections}
              />

              <Route
                path="/home-furniture-enquiries-southatk"
                component={FurnitureRCB}
              />
              <Route path="/Services" component={ServicePageNew} />
              <Route
                path="/home-furniture-enquiries-east"
                component={FurnitureRCB}
              />
              <Route
                path="/home-furniture-enquiries-west"
                component={FurnitureRCB}
              />
                <Route path="/feedback" component={ProductFeedback} />  

              <Route
                path="/home-furniture-enquiries-central" component={FurnitureRCB} />
              <Route path="/home-furniture-enquiries" component={FurnitureRCB} />
        
		  <Route path="/rapid-rooms" component={RapidRooms} />
		  <Route path="/av-solutions" component={GodrejAVSolution} />
		  <Route path="/franchise-enquiry" component={FranchiseEnquiry} />
      
              <Route exact path="/blog" component={BlogContainer} />
              <Route exact path="/blog/:category" component={BlogCategoryContainer} />
              <Route path="/blog/tag/:tag" component={BlogTagContainer} />
              <Route path="/blog/article-:slug/:id" component={BlogDetailContainer} />

              <Route path="/kitchens" component={Kitchens} />
              <Route path="/kitchens-steel-chef" component={SteelChefKitchen} />
              <Route path="/kitchens-willow-wok" component={WillowKitchen} />

              <Route path="/online-furniture-modular-wardrobes" component={WardrobesContainer} />
              <Route path="/online-furniture-modular-wardrobes-kreation-steel" render={() => <ModularWardrobeDetails identifier="kreation" />} />
              <Route path="/online-furniture-modular-wardrobes-kalista-wooden" render={() => <ModularWardrobeDetails identifier="kalista" />} />
              <Route path="/online-furniture-:id" component={ClpContainer} />
              <Route path="/wedding-collections-:id" component={CollectionSet} />
		          <Route path="/furniture-online-:productId/:skuId" component={PdpContainer} />
              <Route path="/furniture-:id" component={PlpContainer} />
              <Route path="/forgotpassword" component={ForgotpassContainer} />
              <Route path="/register" component={RegisterNow} />
              <Route path="/compare" component={CompareContainer} />
              <Route path = "/home-interiors" component = {HomeInteriors} />
              <Route path="/sitemap" component={Sitemap} />
              <Route path="/wishlist" component={MyWishlist} />
              <Route path="/myAccount" component={MyAccount} />
              <Route path="/checkout" component={CheckoutContainer} />
              <Route path="/guestTrackOrder" component={GuestTrackOrder} />
              <Route path="/search" component={PlpContainer} />
              <Route path="/imagesearch" component={ImageSearchListing} />
              <Route path="/order/confirm/:orderId" component={OrderConformtion} />
              <Route path="/cart" component={CartDetail} />
              <Route path="/storelocator" component={StoreLocator} />
              <Route path="/storelocator:storeType" component={StoreLocator} />
              <Route path="/direction/:originLat/:originLng/:destinationLat/:destinationLng" component={Directions} />
              <Route path="/termsconditions" component={TermsConditions} />
              <Route path="/furniture" component={CookiePolicy} />
              <Route path="/cookie-policy" component={CookiePolicy} />
              <Route path="/inspiration" component={Inspiration} />
              <Route path="/shipping" component={Shipping} />
              <Route path="/lookbook" component={InspirationDetails} />
              <Route path="/privacy-policy" component={privacyPolicy} />
              <Route path="/about-us" component={AboutUsContainer} />
              <Route path="/support" component={HelpSupport} />
              <Route path="/serviceRequest" component={ServiceRequestFormGuest} />
                {/* <Route path="/invoice/:invoiceId" component={Invoice} /> */}
              <Route path="/check/payment/:orderId" component={paymentWait} />
              <Route path="/covid19_health_safety_measures" component={Covid19Awareness} />
              <Route path="/request-callback" component={CustomerLeads} />
              <Route path="/street-view" component={StreetViewComponent} />
              <Route path="/business" render={this.redirectToB2B} />
              <Route path="/internet-error" component={InternetError} />
              <Route path="*" component={NotFound} />
              <Route path="/502" component={Maintenance} />
            </Switch>
            </Suspense>
          </LastLocationProvider>
        </div>
        {this.mountPageFooter()}
        <ReactSnackBar Icon={<span><MWebLogo width="24" height="24" /></span>} Show={this.state.showSnackBar}>
          {this.state.internetErrorMessage}
        </ReactSnackBar>
      </div>
      </>
    );
  }

  redirectToB2B() {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });

    apiManager
      .get('/B2B_Redirection')
      .then(response => {
        window.location.reload();
      })
      .catch(error => {
        window.location.reload();
      })
  }

}

export const history = createBrowserHistory();	
