import md5 from 'md5';
// import { createCipheriv } from 'crypto';
import apiManager from './apiManager';
import {
  socialLoginAPI,
  accessTokenCookie,
} from '../../public/constants/constants';
import appCookie from './cookie';
const {Buffer} = require('buffer');

export function onFacebookResponse(socialData, callback) {
  socialLoginAPIHandler(socialData, callback);
}

export function onGoogleResponse(socialData, callback) {
  socialLoginAPIHandler(socialData, callback);
}

function socialLoginAPIHandler(socialData, callback) {
  const data = {
    firstName: socialData.firstName,
    nickName: socialData.firstName,
    lastName: socialData.lastName,
    authorizationProvider: socialData.authorizationProvider,
    id: socialData.emialId,
    accessToken: socialData.socialToken,
    email: socialData.emialId,
  };

  const iv = "1234567887654321";
  const skey = 'cBnfZX3atf4PQRggFAGQjzCrZ9XeFgAa';
  import('crypto').then(crypto =>{
    const cipher = crypto.createCipheriv('aes-256-cbc', skey, iv);
    const encodedText = Buffer.from(JSON.stringify(data));
    let encryptedPassword =cipher.update(encodedText, 'utf8', 'base64') ;
    encryptedPassword += cipher.final('base64')
    const preSeal = encryptedPassword + skey;
    const seal = md5(preSeal);
    const tempData = { epwd: encryptedPassword}
  
    apiManager
      .post(socialLoginAPI, tempData)
      .then(response => {
        appCookie.set('isLoggedIn', true, 365 * 24 * 60 * 60 * 1000);
        //   appCookie.set(
        //     `${accessTokenCookie}=${
        //       response.data.data.access_token
        //     };path=/;expires=''`,
        //   );
      
      //  appCookie.set(
        //     `userID=${
        //       response.data.data.userID
        //     };path=/;expires=''`,
        //   );
        appCookie.set(accessTokenCookie, response.data.data.access_token, 6 * 60 * 60 * 1000);
        appCookie.set('userID', response.data.data.userID, 6 * 60 * 60 * 1000);
        window.location.reload();
        callback('Success');
      })
      .catch(error => {
        callback('Error');
      });
  })
}
