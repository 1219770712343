import React from 'react';
import { host } from '../../../public/constants/constants';
import JsonLd from './JsonLd';

class BreadCrumbSchema extends React.Component{

  render(){
    const data = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",

    }
    const entity = []
    this.props.breadcrumbs.map((qa,key) =>{
      const entityJson = {
        "@type": "ListItem",
        "position": `${key + 1}`,
        "name": `${qa.name}`,
        "item": `${window.location.protocol}//${host}${qa.url}`,
      }
      entity.push(entityJson)
    })
    data.itemListElement = entity;
    return(
      <>
        <JsonLd data={data} />
      </>
    )
  }
 
}

export default BreadCrumbSchema;
