/*
 * primitives Constants
 * 
 * 
 * 
 * 
 
 * 
 * 
 */

export const BOOK_CONSULTATION = 'Book a Consultation';
export const KITCHEN_CONSULT =
  'Take the first step towards making your dream kitchen a reality by meeting our in-house design expert to discuss your design. Simply fill in the form below to book your appointment';
export const WARDROBE_CONSULT =
  'A modular wardrobe that suits your needs and space requirements is now only a click away. <br />Simply share your details and we will reach out to you soon.';
export const FULL_NAME = 'Full Name';
export const EMAIL = 'EMAIL';
export const MESSEGE = {
  default: 'Message',
  kitchen: 'Message',
  wardrobes: 'Requirement',
};
export const MOBILE_NUMBER = 'Mobile Number';
export const WHAT_YOU_LIKE = {
  default: 'What Would you Like to Do',
  kitchen: 'What Would you Like to Do',
  wardrobes: 'Choose Product',
};
export const SELECT_OPTION = 'Select Option';
export const SEND = 'SEND';
export const HELLO_START = 'Hello Start Exploring';
export const HELLO_GUEST = 'Hello Guest';
export const EXPLORE_ROOMS = 'Explore By Room';
export const RECENTLY_VIEWED = 'Recently Viewed';
export const ENTER_OTP = 'Enter OTP';
export const VERIFICATION_CODE = 'Please enter the verification code sent to your mobile number';
export const XXXX_SENT = 'OTP sent to xxxxxx';
export const RESEND_OTP = 'RESEND OTP';
export const REGISTER = 'REGISTER';
export const FACEBOOK_SIGN_IN = 'Sign in with Facebook';
export const LOGIN = 'LOGIN';
export const ALREADY_MEMBER = 'Already a member?';
export const MOBILE = 'MOBILE';
export const GOOGLE_SIGN_IN = 'Sign in with Google';
export const JOIN_US = 'Join us Now';
export const OR = 'or';
export const EMAIL_ADD = 'Email Address';
export const T_C = 'Terms & Conditions';
export const YOU_AGREE = 'By clicking register you agree to our';
export const PASSWORD = 'Password';
export const ALREADY_HAVE_PASSWORD = 'Already have an account?';
export const SUGGESTIONS = 'Suggestions';
export const SEARCH_PAGE = 'Search Page';
export const DIRECTIONS = 'GET DIRECTIONS';
export const STREET_VIEW = 'VIRTUAL TOUR';
export const OUT_OF_STOCK = 'OUT OF STOCK';
export const HELLO = 'Hello';
export const TRACK_ORDER = 'Track Your Order';
export const ITEM = 'ITEM';
export const SHIPMENT = 'SHIPMENT#';
export const ORDER_ID = 'Order ID';
export const DELIVERY_ON = 'Delivery on:';
export const VIEW_ORDER = 'VIEW ORDER';
export const MY_ORDER = 'My Orders';
export const MANAGE_ADDRESSES = 'Manage Addresses';
export const WELCOME_INTERIO = 'Welcome to Interio!';
export const LOGIN_REGISTER = 'Login/Register';
export const FULLNAME_MSG = 'Please enter your full name';
export const VALIDNAME_MSG = 'Please enter a valid Name. It should not exceed 64 characters';
export const VALIDEMAIL_MSG = 'Please enter valid Email Id';
export const SELECT_OPTION_MSG = 'Please select an option';
export const ENTER_MSG = 'Please enter message';
export const MOBILE_MSG = 'Please enter valid Mobile Number';
export const FEEDBACK_MSG = 'Thank you for the feedback';
export const ENTEROTP_MSG = 'Please enter OTP';
export const INCORRECTOTP_MSG = 'OTP entered is incorrect';
export const MAXOTP_ATTAMPTS_MSG = 'OTP cannot be regenerated. You have exceeded the maximum number of resending attempts (3)';
export const STORELOCATOR_TITLE = 'Experience our products first hand at your nearest Godrej Interio store';
export const MINCOMPARECOUNT_MSG = 'Please add at least two products to compare';

// Validation MSG
export const FIELDREQ_MSG = 'This field is required';
export const NAME_VALIDATION_MSG = 'Please enter a valid Name. It should not exceed 64 characters';
export const PASSWORD_MIN_VALIDATION_MSG = 'Invalid Password. Password should have min 6 characters and atleast 1 number';
export const PASSWORD_MAX_VALIDATION_MSG = 'Invalid Password. Password should have max 25 characters and atleast 1 number';
export const VALID_EMAILNUMBER_MSG = 'Please enter valid Email id/Mobile Number';
export const VALID_PASSWORD_MSG = 'Enter a valid password';
export const VALID_INCORRECTP_PASS_MSG = 'LogonId or Password is incorrect';
export const COMPARE_SWATCH_SWITCH_ERROR_MSG ='This color of the product is already added in compare list. Please choose other color.';
export const INVALID_MOBILE_NUMBER = 'Please enter valid Mobile Number';
export const INVALID_EMAIL_ADDRESS = 'Please enter valid Email Address';
export const INVALID_PINCODE_FORMAT='Please enter a valid Zip Code';
export const ATLEAST_ONE_REQUIREMENT='Please select atleast one requirement';
